/*Modul 011300 */

.cel-layout-wrapper{
  width : 100%;
  margin-bottom: 20px;
}

.cel-layout-menu-style{
  box-shadow: 1px 3px 13px 0px rgba(0,0,0,0.48);
  height: 100%;
  background: #FFF;
}

.cel-top-menu{
  margin-bottom: 20px;
}



#cel-panel-left{
  padding: 10px !important;
}

.cel-panel-right{
  padding: 10px !important;
}

.cel-card-wrapper{
  border-style: solid;
  border-width: 2px;
  border-color: $primary-color;
  min-height: 200px;
  padding:0 10px 10px 10px;
  float:left;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #FFF;
}

.cel-card-wrapper-header{
  border-color: $primary-color;
  float:left;
  position: relative;
  width: 100%;
  background-color: #FFF;
}

.cel-card-art-tag{
  position:relative;
  top: 0px;
  left: 30px;
  color:#FFF;
  background-color:$primary-color;
  display: inline-block;
  padding: 10px;
  margin-right:10px;
}

.cel-panels-header{
  height : 30px;
  background-color:$primary-color;
}

.cel-layouts-header{
  background-color: $primary-color;
  color: white;
  font-weight: bold;
  padding: 11px;
}


.cel-panels-header-text{
  margin-top: 4px;
  color: #FFF;
  font-weight: bold;
  padding-left: 5px;
  height: 100%;
  display: inline-block;
}

.cel-panels-header-wrapper{
  border:2px solid $primary-color;
}

.cel-card-art-no-tag{
  height:25px;
}

.cel-card-dat-name{
  margin-top: 10px;
  font-size: 18px;
  color: $primary-color;
  padding-bottom: 10px;
}

.cel-card-dat-subtitle{
  font-size: 14px;
  color: $primary-color;
  padding-bottom: 2px;
}

.cel-card-trainer-title{
  font-size: 16px;
  color: $primary-color;
  padding-bottom: 5px;
}

.cel-card-dat-desc{
  font-size: 16px;
  padding-bottom: 10px;
}

.cel-card-dat-terms{
  position: relative;
  border-top: 1px solid $primary-color;
  margin-left: -10px;
  margin-right: -10px;
  padding: 10px 10px 0;
  margin-bottom: 50px;
  min-height: 58px;
  .fa-clock-o{
    padding-right: 10px ;
  }
  .btn-group{
    position: absolute;
    right: 10px;
    bottom: 10px;
  }
}
#cel-cards-row {
  .cel-card-button-div {
    height: 40px;
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
}
.cel-card-dat-button-pos{
  float: right;
}

.cel-card-status-tag{
  padding-right: 5px;
}

.cel-row-container-one {
  display: grid;
  grid-template-columns: 1fr; /*fraction*/
}

.cel-row-container-two {
  display: grid;
  grid-template-columns: 1fr 1fr; /*fraction*/
}

.cel-row-container-three {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; /*fraction*/
}

.cel-row-section {
  /* border: 1px solid; */
  padding:10px 10px 10px;
}


.cel-zoom-card {
  transition: transform .2s;
}

.cel-zoom-card:hover {
  transform: scale(1.03);
  background-color: lightgray;
  -webkit-box-shadow: 1px 3px 13px 0px rgba(0,0,0,0.48);
  -moz-box-shadow: 1px 3px 13px 0px rgba(0,0,0,0.48);
  box-shadow: 1px 3px 13px 0px rgba(0,0,0,0.48);
}

.cel-cat-meta-header{
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.cel-meta-link,
.cel-category-link{
  display: block;
}

.search-banner{
  padding: 0 10px 20px;
}

#cel-event-cat-slider{
  .carousel-caption{
    background-color: #FFF;
    color: #333333;
    text-shadow: none;
    right: 0px;
    left: 0px;
    bottom: 0px;
    opacity: 0.95;
    padding: 10px 20px;
    p{
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    h3{
      margin-top:10px ;
      margin-bottom:10px ;
    }
  }
  .cel-img-dummy{
    background-color: lightgray;
    display: block;
    max-width: 100%;
    height: 328px;
    line-height: 1;
  }
}

/* Small devices (mobilephone, 320px and up) */
@media (min-width: $screen-xxs-min) and (max-width: $screen-xxs-max){
  .cel-row-container-one,
  .cel-row-container-two,
  .cel-row-container-three
  {
    grid-template-columns: none;
  }
}

/* Small devices (tablets, 768px and up) */
@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
  .cel-row-container-one,
  .cel-row-container-two,
  .cel-row-container-three
  {
    grid-template-columns: none;
  }
  .search-banner {
    padding-top: 20px;
  }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: $screen-md-min) {
  #cel-date-search-button,
  #cel-reset-search-button{
    margin-bottom: 10px;
  }
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: $screen-lg-min) {
  #cel-date-search-button,
  #cel-reset-search-button{
    margin-bottom: 0;
  }
}
