.list-group-item {
   margin-bottom: 5px;
   .cel-calendar-add{
      margin-top: -10px;
      margin-left: 10px;
      margin-right: -15px;
      padding: 12px;
      float: right;
   }
   .input-group-btn .btn{
      padding-bottom: 7px;
   }
   .cel-time{
      margin-right: 10px;
   }
}

.fc td.fc-event-container .fc-content{
   text-align: center;
   padding-top: 5px;
   padding-bottom: 5px;
}

.cel-resource-selection{
   border-top:1px solid lightgray ;
   .cel-cancel,
   .cel-add-elm,
   input.cel-amount{
      height: 35px;
   }
}

#cel-resource-title{
   margin-top: 32px;
}