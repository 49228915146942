#cel-event-catalogs {
  .event-title{
    padding-bottom: 5px;
    display: inline-block;
  }
  .event-subtitle {
    display: inline-block;
    &:before {
      content: '- ';
    }
  }
  .panel-body{
    padding: 15px;
  }
  .upcoming-date-list > thead > tr > th, .table > thead > tr > td, .table > tbody > tr > th, .table > tbody > tr > td, .table > tfoot > tr > th, .table > tfoot > tr > td{
    border: none;
  }
}


.event-title{
  font-weight: bold;
  padding-bottom: 5px;
}

#cel-event-catalogs{
  form{
    margin-bottom: 50px;
  }
}

.cel-event-target-group.tile,
.cel-event-catalog-category.tile,
.cel-eventcatbox {
  display: inline-flex;
  .cel-event-target-group-content,
  .cel-event-catalog-category-content,
  .cel-eventcatbox-content{
    overflow: hidden;
    position: relative;
    //background-color: rgb(86, 86, 86);
    background-color:white ;
    width: 150px;
    height: 150px;
    margin-top: 10px;
    margin-right: 10px;
    float: left;
    .cel-target-group-image,
    .cel-category-image,
    .cel-eventcatbox-backgroundimg{
      height: 100%;
      width: 100%;
    }
    .cel-target-group-name,
    .cel-category-name,
    .cel-eventcatbox-title{
      width: 150px;
      height: 50px;
      top: 100px;
      left: 0px;
      font-size: 11px;
      color: rgb(255, 255, 255);
      a{
        color: rgb(255, 255, 255);
      }
      position: absolute;
      background-color: rgba(0, 0, 0, 0.6);
      padding: 7px;
    }
  }
}

.cel-category-head,
.cel-catalog-head{
  h2 {
    text-align: center;
  }
}

.cel-category-head{
  text-align: center;
  h2, .cel-category-img{
    display: inline-block;
  }
}

.cel-eventcatbox{
  margin-bottom: 10px;
}

