.article_list .input_article_amount {
   width: 75px;
}
#shop-basket-form{
   position: relative;
   .ajax-loader {
      visibility: hidden;
      background-color: #9999998f;
      position: absolute;
      z-index: +100 !important;
      width: 100%;
      height: 100%;

   }

   .ajax-loader .fa {
      position: relative;
      top: 50%;
      left: 50%;
      margin: auto;
   }
}

.cel-tab-wrapper {
   position: relative;
   .ajax-loader {
      visibility: hidden;
      background-color: #9999998f;
      position: absolute;
      z-index: +100 !important;
      width: 100%;
      height: 100%;
      text-align: center;
      font-size: 30px;

      .fa {
         position: absolute;
         left: 0%;
         right: 0%;
         margin: auto;
      }

      .cel_ajax_loader_text {
         position: absolute;
         top: 50%;
         left: 0%;
         right: 0%;
         margin: auto;
      }
   }
}

.cel-instapp-desc{
   width: 50%;
}
#confirmation-page{
   .panel-body{
      .btn-group{
         margin-bottom: 5px;
      }
   }
}