/*Footer*/
footer.cel-footer-wrapper{
  .cel-footer-inner-wrapper {
    padding: 30px 30px 10px;
    background-color: #262626;
    color: lightgrey;
  }
  li{
    display: block;
  }
  .cel-subfooter{
    background-color: #222;
    text-align: center;
    padding: 10px 30px;
    margin-left: -15px;
    margin-right: -15px;
    clear: both;
    .cel-copyright{
      display: none;
    }
    &:after {
      content: " ";
      display: table;
      clear: both;
    }
    p{
      margin:0;
      color: lightgrey;
    }
    ul li{
      float: left;
      a {
        text-decoration: none;
        color: #004477;
      }
    }
    .cel-footermenu{
      float: right;
      padding-left:5px ;
    }
  }
  .cel-footer{
    text-align: right;
  }
  .wpex-info-widget .fa {
    position: absolute;
    left: 0;
    top: 0;
    font-size: 1.231em;
  }
  .wpex-info-widget div {
    padding-left: 2.308em;
    position: relative;
    line-height: 1.5;
    margin-bottom: 10px;
  }
}