#cel-event-details{
  .table > thead > tr > th,
  .table > thead > tr > td,
  .table > tbody > tr > th,
  .table > tbody > tr > td,
  .table > tfoot > tr > th,
  .table > tfoot > tr > td {
    border: none;
  }
  tr{
    th,
    td:first-child{
      padding-left: 0;
    }
  }
  .separator{
    display:none;
  }
}

#cel-event-details {

  .panel-default > .panel-heading {
    color: #333;
    background-color: #f5f5f5;
    border-color: #ddd;
  }
  h4{
    font-size: 16px;
  }
  .panel-default {
    border-color: #ddd;
  }
  .cel-button-group{
    position: absolute;
    bottom: 34px;
    right: 34px;
  }
  .panel{
    height: 100%;
    padding-bottom: 30px;
    border-color: #ddd;
  }
  .panel-body{
    padding-right: 15px;
  }
  .row-eq-height {
    display:         flex;
  }
  hr{
    border-color: #ddd;
    margin: 10px 0;
  }
  .cel-date-list .cel-space{
    margin-top:5px;
    &.cel-date-hour{
      padding-top: 15px;
    }
  }
  .cel-calendar-link{
    display: block;
  }
}

#cel-event-details {
  .cel-topics {
    $cel-event-topic-grades-active-color: #ef4439;
    $cel-event-topic-grades-inactive-color: #d2d1d3;
    margin-bottom: 20px;
    margin-top: 20px;
    &.panel {
      padding-bottom: 0;
    }
    .cel-topics-row {
      margin-bottom: -1px;
    }
    .cel-topics-levels {
      display: inline-block;
      float: right;
      .cel-topics-level {
        border-radius: 50%;
        display: inline-block;
        height: 16px;
        width: 16px;
        &.active {
          background-color: $cel-event-topic-grades-active-color;
        }
        &.filler {
          background-color: transparent;
        }
        &.inactive {
          background-color: $cel-event-topic-grades-inactive-color;
        }
      }
    }
    .cel-topics-title {
      text-align: left;
    }
  }
}

#cel-event-pdfs {
  li{
    padding-bottom: 4px;
    list-style-type: none;
  }
  ul{
    padding-left: 0px;
  }
}